import * as React from "react";
import { Helmet } from "react-helmet";
import { FrameProps } from ".";

export const Frame: React.FC<FrameProps> = ({ children, topboar, footer }) => {
  return (
    <div>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@700&family=Roboto&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {children}
    </div>
  );
};

export default Frame;
