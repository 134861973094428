import { PageProps } from "gatsby";
import * as React from "react";

import Seo from "../components/seo";
import Frame from "../components/frame";
import Layout from "../components/layout";

const IndexPage = (props: PageProps) => {
  return (
    <Frame topboar={<div />} footer={<div />}>
      <Seo title="John Soriano" description="" keywords={[]} pathSlug="/" />
      <Layout>Hello</Layout>
    </Frame>
  );
};

export default IndexPage;
